
import {AlertDialog as Original} from 'native-base';
import {IAlertDialogProps} from 'native-base/lib/typescript/components/composites';
import {IIconButtonProps} from 'native-base/lib/typescript/components/composites/IconButton';
import {IBoxProps} from 'native-base/lib/typescript/components/primitives/Box';

type iAlertDialog = IAlertDialogProps
const AlertDialog = (props: iAlertDialog) => {
  return <Original {...props} />;
};

type iAlertDialogContent = IBoxProps;
export const AlertDialogContent = (props: iAlertDialogContent) => {
  return <Original.Content {...props} />
}

type iAlertDialogCloseButton = IIconButtonProps;
export const AlertDialogCloseButton = (props: iAlertDialogCloseButton) => {
  return <Original.CloseButton {...props} />
}

type iAlertDialogHeader = IBoxProps;
export const AlertDialogHeader = (props: iAlertDialogHeader) => {
  return <Original.Header {...props} />
}

type iAlertDialogBody = IBoxProps;
export const AlertDialogBody = (props: iAlertDialogBody) => {
  return <Original.Body {...props} />
}

type iAlertDialogFooter = IBoxProps;
export const AlertDialogFooter = (props: iAlertDialogFooter) => {
  return <Original.Footer {...props} />
}

export default AlertDialog;
