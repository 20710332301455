import View from '../../../components/framework/View';
import {studentAbsenceScreenStyle} from '../../../AppStyles';
import Center from '../../../components/framework/Center';

type iStudentAbsenceContentPanel = {
  children: any;
  style?: any;
}

const StudentAbsenceContentPanel = ({children, style = []}: iStudentAbsenceContentPanel) => {
  return (
    <View style={style}>
      <Center>
        <View style={[studentAbsenceScreenStyle.searchContentPanel]}>
          {children}
        </View>
      </Center>
    </View>
  )
}

export default StudentAbsenceContentPanel;
