import Box from '../framework/Box';

type iShowToast = {
  msg: string,
  toast: any,
  bg?: string;
  _text?: any;
  placement?: string;
}
const showToast = ({msg, toast, placement = 'top', bg = 'info.600', _text = { fontSize: 'md', fontWeight: 'bold', color: 'white' }}: iShowToast) => {
  toast.show({
    placement,
    render: () => (
      <Box bg={bg} rounded={'sm'} mb={5} p={4} shadow={2} _text={_text} >
        {msg}
      </Box>
    )
  })
}

const showErr = (msg: string, toast: any, placement = 'top') => {
  showToast({
    placement,
    msg: msg,
    bg: 'error.600',
    toast,
  })
}

const showApiErr = (error: any, toast: any, placement = 'top') => {
  showErr(`${error.response?.data.message || error.message}`, toast, placement)
}


const showSuccess = (msg: string, toast: any, placement = 'top') => {
  showToast({
    placement,
    msg,
    bg: 'success.600',
    toast,
  })
}

const showWarning = (msg: string, toast: any, placement = 'top') => {
  showToast({
    placement,
    msg,
    bg: 'warning.600',
    _text: { fontSize: 'md', fontWeight: 'bold', color: 'black'},
    toast,
  })
}

const showInfo = (msg: string, toast: any, placement = 'top') => {
  showToast({
    placement,
    msg,
    bg: 'info.600',
    toast,
  })
}

const Toaster = {
  showErr,
  showApiErr,
  showSuccess,
  showWarning,
  showInfo,
}

export default Toaster
