import iVStudent from '../../../types/Synergetic/iVStudent';
import Text from '../../../components/framework/Text';
import Heading from '../../../components/framework/Heading';
import HStack from '../../../components/framework/HStack';
import View from '../../../components/framework/View';
import Button from '../../../components/framework/Button';
import {Icons} from '../../../components/framework/Icon';
import {screenStyle} from '../../../AppStyles';
import {
  iStudentAbsence,
  STUDENT_ABSENCE_REASON_CODE_OTHER,
} from '../../../types/StudentAbsence/iStudentAbsence';
import Center from '../../../components/framework/Center';
import FormControl, {FormControlLabel} from '../../../components/framework/FormControl';
import {useEffect, useRef, useState} from 'react';
import StudentAbsenceService from '../../../services/StudentAbsences/StudentAbsenceService';
import moment from 'moment';
import {useToast} from 'native-base';
import Toaster from '../../../components/common/Toaster';
import Select, {SelectItem} from '../../../components/framework/Select';
import Input from '../../../components/framework/Input';

type iStudentAbsenceSubmitByExpectedPanel = {
  student: iVStudent;
  absenceType: string;
  expectedEvents: iStudentAbsence[];
  style?: any;
  onCancel: () => void;
  onBackToNormal: () => void;
  onSubmitted: () => void;
}
const StudentAbsenceSubmitByExpectedPanel = ({student, onBackToNormal, expectedEvents, onCancel, absenceType, style = [], onSubmitted}: iStudentAbsenceSubmitByExpectedPanel) => {
  const toaster = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<iStudentAbsence | null>(null);
  const [optionsMap, setOptionsMap] = useState<{ [key: string]: iStudentAbsence }>({});
  const comments = useRef('');

  useEffect(() => {
    setOptionsMap(
      expectedEvents.reduce((map, event) => ({...map, [`${event.id}`]: event}), {})
    );
    setSelectedEvent(expectedEvents.length > 0 ? expectedEvents[0] : null);
    comments.current = '';
  }, [expectedEvents]);

  const getAbsenceTypeName = () => {
    return StudentAbsenceService.getAbsenceTypeName(absenceType);
  }

  const submit = () => {
    const data = {
      type: absenceType,
      StudentID: student.StudentID,
      EventDate: moment().toISOString(),
      AbsenceCode: selectedEvent?.AbsenceCode || STUDENT_ABSENCE_REASON_CODE_OTHER,
      Comments: comments.current,
      expectedEventId: selectedEvent?.id,
    }
    setIsSubmitting(true);
    StudentAbsenceService.submitByStudent(data).then(resp => {
      comments.current = '';
      Toaster.showSuccess(`Submitted Successfully`, toaster);
      onSubmitted();
    }).catch(err => {
      Toaster.showApiErr(err, toaster);
    }).finally(() => {
      setIsSubmitting(false);
    })
  }

  const getCommentsDiv = () => {
    return(
      <FormControl style={{marginTop: 14}}>
        <FormControlLabel>
          <Text style={[screenStyle.redScreenText]}>Your Comments</Text>
        </FormControlLabel>
        <Input
          isDisabled={isSubmitting}
          defaultValue={comments.current}
          placeholder={'Optional, Student comments...'}
          w={'100%'}
          style={{backgroundColor: 'white', paddingTop: 14, paddingBottom: 14}}
          onChangeText={(newTxt) => {
            comments.current = newTxt;
          }}
        />
      </FormControl>
    )
  }

  return (
    <View style={style}>
      <Center>
        <Heading style={[screenStyle.redScreenText]}>
          {getAbsenceTypeName()}
        </Heading>
      </Center>
      <FormControl isRequired style={{marginTop: 14}}>
        <FormControlLabel>
          <Text style={[screenStyle.redScreenText]}>Found ({expectedEvents.length}) Expected {getAbsenceTypeName()}, please select one below:</Text>
        </FormControlLabel>
        <Select
          dropdownIcon={
            <Center style={{backgroundColor: 'white', paddingRight: 12, paddingTop: 14, paddingBottom: 14}}>
              <Icons.ChevronDownIcon />
            </Center>
          }
          style={{backgroundColor: 'white', paddingTop: 14, paddingBottom: 14}}
          color={'muted.900'}
          placeholderTextColor={'muted.900'}
          variant={'unstyled'}
          selectedValue={`${selectedEvent?.id || ''}`.trim()}
          onValueChange={(value) => {
              if (`${value}` in optionsMap) {
                setSelectedEvent(optionsMap[`${value}`]);
                return;
              }
              onBackToNormal();
            }}
            _selectedItem={{
              bg: "teal.600",
              endIcon: <Icons.CheckIcon size="5" />
            }}
          >
          {
            Object.values(optionsMap).map(event => {
              return (
                <SelectItem key={event.id} label={`Reason: ${`${event.AbsenceReason?.Description || ''}`.trim() === '' ? event.AbsenceCode : event.AbsenceReason?.Description } | Comments: ${event.Comments}`} value={`${event.id}`} />
              )
            })
          }
          <SelectItem key={'back-to-normal'} label={`None of above, let me type in manually.`} value={''} />
        </Select>
      </FormControl>
      {getCommentsDiv()}
      <HStack justifyContent={'space-between'} style={{marginTop: 30}}>
        <Button
          isLoading={isSubmitting}
          colorScheme={'primary'}
          variant={'unstyled'}
          onPress={() => onCancel()}
          leftIcon={<Icons.CloseIcon style={[screenStyle.redScreenText]} />}
        >
          <Text style={[screenStyle.redScreenText]}>Cancel</Text>
        </Button>
        <Button
          isLoading={isSubmitting}
          style={{paddingTop: 14, paddingBottom: 14, width: '30%'}}
          colorScheme={'primary'}
          onPress={() => submit()}
          leftIcon={<Icons.Submit />}
        >
          Submit
        </Button>
      </HStack>
    </View>
  )
}

export default StudentAbsenceSubmitByExpectedPanel;
