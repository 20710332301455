const HOME = 'HOME';
const DEVICE_INFO = 'DEVICE_INFO';
const STUDENT_ABSENCE = 'StudentAbsence';
const CAMPUS_DISPLAY = 'CampusDisplay';

const Urls = {
  HOME,
  DEVICE_INFO,
  STUDENT_ABSENCE,
  CAMPUS_DISPLAY,
}

export default Urls;
