import {FlatList as OriginalList} from 'native-base';
import {IFlatListProps} from 'native-base/lib/typescript/components/basic/FlatList';

type iFlatList = IFlatListProps<any>;

const FlatList = (props: iFlatList) => {
  return <OriginalList {...props} />;
}

export default FlatList;
