import {ISelectItemProps, ISelectProps, Select as Original} from 'native-base';

type iSelect = ISelectProps;
const Select = (props: iSelect) => {
  return <Original {...props} />;
}

type iSelectItem = ISelectItemProps;
export const SelectItem = (props: iSelectItem) => {
  return <Original.Item {...props} />;
}

export default Select;
