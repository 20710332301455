import axios, {AxiosRequestConfig} from 'axios';
import LocalStorageService, {
  LOCAL_STORAGE_API_END_POINT,
  LOCAL_STORAGE_APP_TOKEN_NAME,
  LOCAL_STORAGE_AUTH_TOKEN_NAME
} from './LocalStorageService';

export const DEFAULT_END_POINT = 'https://mconnectweb03.mentonegirls.vic.edu.au';
export const HEADER_NAME_APP_TOKEN = 'X-MGGS-TOKEN';
export const HEADER_NAME_SELECTING_FIELDS = 'X-MGGS-SELECT-FIELDS';

export type iConfigParams = {
  [key: string]: any;
};
export type iParams = {
  [key: string]: string | boolean | number | null | undefined | string[];
};

const getEndPointUrl = async (url: string) => {
  const apiEndPoint = await LocalStorageService.getItem(LOCAL_STORAGE_API_END_POINT);
  if (`${apiEndPoint || ''}`.trim() === '') {
    return `${DEFAULT_END_POINT}${url}`;
  }
  return `${apiEndPoint}${url}`
};

const getHeaders = async (extra = {}) => {
  const token = await LocalStorageService.getItem(LOCAL_STORAGE_AUTH_TOKEN_NAME);
  const authHeader = (!token || token === '') ? {} : {Authorization: `Bearer ${token}`};
  return {
    headers: {
      [HEADER_NAME_APP_TOKEN]: `${(await LocalStorageService.getItem(LOCAL_STORAGE_APP_TOKEN_NAME)) || ''}`,
      ...authHeader,
      ...extra,
    },
  };
};

const getUrlParams = (params: iConfigParams = {}) => {
  const paramString =
    typeof params === 'object' && Object.keys(params).length > 0
      ? new URLSearchParams(params).toString()
      : '';
  return paramString === '' ? '' : `?${paramString}`;
};

const get = async (url: string, params: iConfigParams = {}, config: AxiosRequestConfig = {}) => {
  const {headers, ...rest} = config;
  return axios.get(
    `${await getEndPointUrl(url)}${getUrlParams(params)}`,
    // @ts-ignore
    {
      ...rest,
      ...(await getHeaders(headers))
    },
  );
};

const post = async (url: string, params: iParams, config: AxiosRequestConfig = {}) => {
  // @ts-ignore
  return axios.post(await getEndPointUrl(url), params, {
    ...config,
    ...(await getHeaders())
  });
};

const put = async (url: string, params: iConfigParams, config: AxiosRequestConfig = {}) => {
  // @ts-ignore
  return axios.put(getEndPointUrl(url), params, {
    ...config,
    ...(await getHeaders())
  });
};

const remove = async (url: string, params: iConfigParams = {}, config: AxiosRequestConfig = {}) => {
  return axios.delete(
    `${await getEndPointUrl(url)}${getUrlParams(params)}`,
    // @ts-ignore
    {
      ...config,
      ...(await getHeaders())
    }
  );
};
const getUploadHeaders = async () => {
  const headers = await getHeaders();
  return {
    headers: {
      ...headers,
      'Content-Type': 'multipart/form-data',
    },
  };
};
const uploadImage = async (url: string, params: FormData, config: AxiosRequestConfig = {}) => {
  // @ts-ignore
  return axios.post(await getEndPointUrl(url), params, {
    ...config,
    ...(await getUploadHeaders())
  });
};

const AppService = {
  get,
  post,
  put,
  delete: remove,
  uploadImage,
};

export default AppService;
