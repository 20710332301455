import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import iMggAppDevice from '../../types/Settings/iMggAppDevice';

export type AppState = {
  isProd?: boolean;
  deviceInfo?: iMggAppDevice;
  selectedScreen?: string;
  appToken?: string;
};
/**
 * Initial State
 */
const initialState: AppState = {
};

/**
 * Actions
 */
const actions = {
  setApp: (
    state: AppState = initialState,
    action: PayloadAction<AppState>,
  ) => ({
    ...state,
    isProd: action.payload.isProd,
    deviceInfo: action.payload.deviceInfo,
    appToken: action.payload.appToken,
    selectedScreen: action.payload.selectedScreen,
  }),
};
/**
 * Slice
 */
const AppSlice = createSlice({
  name: 'App',
  initialState,
  reducers: actions,
});
/**
 * action
 */
export const { setApp } = AppSlice.actions;
/**
 * reducer
 */
export default AppSlice.reducer;
