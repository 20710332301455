// @ts-ignore
import {REACT_APP_LOCAL_USER_TOKEN_NAME, REACT_APP_LOCAL_APP_TOKEN_NAME} from '@env';
import AsyncStorage from '@react-native-async-storage/async-storage';

export const LOCAL_STORAGE_API_END_POINT = 'MGG_API_END_POINT';
export const LOCAL_STORAGE_APP_TOKEN_NAME = 'MGG_APP_TOKEN';
export const LOCAL_STORAGE_AUTH_TOKEN_NAME = 'MGG_AUTH_TOKEN';
export const LOCAL_STORAGE_DEVICE_INFO_NAME = 'MGG_DEVICE_INFO';
export const LOCAL_STORAGE_MGG_APP_IS_PROD = 'MGG_APP_IS_PROD';
export const LOCAL_STORAGE_SELECTED_SCREEN = 'SELECTED_SCREEN';
export const LOCAL_STORAGE_SELECTED_DISPLAY_LOCATION_ID = 'SELECTED_DISPLAY_LOCATION_ID';
const getItem = async (name: string) => {
  try {
    const value = await AsyncStorage.getItem(`@${name}`);
    return JSON.parse(`${value || ''}`);
  } catch (e) {
    // console.error(e);
  }
};

const setItem = async (name: string, value: any) => {
  try {
    await AsyncStorage.setItem(`@${name}`, JSON.stringify(value));
  } catch (e) {
    // console.error(e);
  }
};

const removeItem = async (name: string) => {
  try {
    await AsyncStorage.removeItem(`@${name}`);
  } catch (e) {
    // console.error(e);
  }
};

const clearAll = async () => {
  try {
    await AsyncStorage.clear();
  } catch (e) {
    // console.error(e);
  }
}

const LocalStorageService = {
  setItem,
  getItem,
  removeItem,
  clearAll,
};

export default LocalStorageService;
