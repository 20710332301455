import {
  FormControl as Original, IFormControlErrorMessageProps,
  IFormControlHelperTextProps,
  IFormControlLabelProps,
  IFormControlProps
} from 'native-base';

type iFormControl = IFormControlProps;
const FormControl = (props: iFormControl) => {
  return <Original {...props} />;
}

type iFormControlLabel = IFormControlLabelProps;
export const FormControlLabel = (props: iFormControlLabel) => {
  return <Original.Label {...props} />;
}

type iFormControlHelperText = IFormControlHelperTextProps;
export const FormControlHelperText = (props: iFormControlHelperText) => {
  return <Original.HelperText {...props} />;
}

type iFormControlErrorMessage = IFormControlErrorMessageProps;
export const FormControlErrorMessage = (props: iFormControlErrorMessage) => {
  return <Original.ErrorMessage {...props} />;
}

export default FormControl;
