import {useEffect, useState} from 'react';
import moment from 'moment';
import Text from './framework/Text';
import HStack from './framework/HStack';

type iCurrentTime = {
  title?: string;
  textStyle?: any;
}
const CurrentTime = ({title, textStyle = []}: iCurrentTime) => {

  const [now, setNow] = useState(moment());

  useEffect(() => {
    setTimeout(() => {
      setNow(moment(now).add(1, 'second'));
    }, 1000)
  }, [now])

  return (
    <HStack justifyContent={'start'} space={1}>
      <Text style={textStyle}>
        {title}
      </Text>
      <Text style={textStyle}>
        {moment(now).format('DD MMM YYYY')}
      </Text>
      <Text style={textStyle}>
        ({moment(now).format('dddd')})
      </Text>
      <Text style={textStyle}>
        {moment(now).format('h:mm:ss a')}
      </Text>
    </HStack>
  );
}

export default CurrentTime;
