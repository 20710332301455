import appService, {iConfigParams} from '../AppService';
import {AxiosRequestConfig} from 'axios';
import iPaginatedResult from '../../types/iPaginatedResult';
import iStudentAbsenceSchedule from '../../types/StudentAbsence/iStudentAbsenceSchedule';

const endPoint = '/studentAbsenceSchedule';

const getAll = (params?: iConfigParams, config: AxiosRequestConfig = {}): Promise<iPaginatedResult<iStudentAbsenceSchedule>> => {
  return appService.get(endPoint, params, config).then(({data}) => data);
};

const StudentScheduledAbsenceService =  {
  getAll,
}

export default StudentScheduledAbsenceService;
