import appService, {iConfigParams} from '../AppService';
import {AxiosRequestConfig} from 'axios';
import iPaginatedResult from '../../types/iPaginatedResult';
import {
  iStudentAbsence,
  STUDENT_ABSENCE_RECORD_TYPE_EARLY_SIGN_OUT, STUDENT_ABSENCE_RECORD_TYPE_LATE_SIGN_IN,
} from '../../types/StudentAbsence/iStudentAbsence';
import iMessage from '../../types/Message/iMessage';

const endPoint = '/studentAbsence';

const getAll = (params?: iConfigParams, config: AxiosRequestConfig = {}): Promise<iPaginatedResult<iStudentAbsence>> => {
  return appService.get(endPoint, params, config).then(({data}) => data);
};

const submitByStudent = (params: iConfigParams, config?: AxiosRequestConfig): Promise<iMessage> => {
  return appService.post(`${endPoint}/studentSubmission`, params, config).then(({data}) => data);
}

const getAbsenceTypeName = (type: string) => {
  if(type === STUDENT_ABSENCE_RECORD_TYPE_EARLY_SIGN_OUT) {
    return 'Early Sign-outs';
  }
  if(type === STUDENT_ABSENCE_RECORD_TYPE_LATE_SIGN_IN) {
    return 'Late Sign-ins';
  }
  return 'unknow';
}

const StudentAbsenceService =  {
  getAll,
  submitByStudent,
  getAbsenceTypeName,
}

export default StudentAbsenceService;
