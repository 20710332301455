import View from '../../components/framework/View';
import Text from '../../components/framework/Text';
import {screenStyle, studentAbsenceScreenStyle} from '../../AppStyles';
import Logo from '../../components/Logo';
import HStack from '../../components/framework/HStack';
import Center from '../../components/framework/Center';
import {useState} from 'react';
import StudentEmailSearchPanel from './components/StudentEmailSearchPanel';
import StudentIDSearchPanel from './components/StudentIDSearchPanel';
import StudentAbsenceContentPanel from './components/StudentAbsenceContentPanel';
import Button from '../../components/framework/Button';
import ButtonGroup from '../../components/framework/ButtonGroup';
import SynVStudentService from '../../services/Synergetic/SynVStudentService';
import UtilsService from '../../services/UtilsService';
import iVStudent from '../../types/Synergetic/iVStudent';
import StudentAbsenceTypeSelectPanel from './components/StudentAbsenceTypeSelectPanel';
import {OP_LIKE} from '../../helper/ServiceHelper';
import {HEADER_NAME_SELECTING_FIELDS} from '../../services/AppService';
import Urls from '../../helper/Urls';

const StudentAbsenceScreen = ({navigation}) => {
  const [isShowingEmailSearching, setIsShowingEmailSearching] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [student, setStudent] = useState<iVStudent | null>(null);

  const getSearchTypeSwitch = () => {
    return (
      <ButtonGroup colorScheme={'primary'} variant={'subtle'} isAttached>
        <Button
          onPress={() => setIsShowingEmailSearching(true)}
          style={[
            studentAbsenceScreenStyle.searchTypeBtn,
            ...(isShowingEmailSearching === true ? [studentAbsenceScreenStyle.searchTypeBtnActive] : []),
          ]}
        >
          By Email
        </Button>
        <Button
          onPress={() => setIsShowingEmailSearching(false)}
          style={[
            studentAbsenceScreenStyle.searchTypeBtn,
            ...(isShowingEmailSearching !== true ? [studentAbsenceScreenStyle.searchTypeBtnActive] : []),
          ]}
        >
          By ID
        </Button>
      </ButtonGroup>
    )
  }

  const searchStudent = (keyword: string) => {
    const keywords = `${keyword}`.trim();
    if (keywords === '') {
      return null;
    }
    const where = UtilsService.isNumeric(keyword) ? {
      ID: keywords,
    } : {
      StudentOccupEmail: {[OP_LIKE]: `${keywords}@mentonegirls.vic.edu.au`},
    };
    setIsSearching(true);
    setErrorMsg('');
    SynVStudentService.getCurrentVStudents({
        where: JSON.stringify({
          CurrentSemesterOnlyFlag: true,
          StudentActiveFlag: true,
          ...where,
        }),
        perPage: 1,
      }, {
      headers: {[HEADER_NAME_SELECTING_FIELDS]: JSON.stringify(['StudentID', 'StudentSurname', 'StudentGiven1'])}
    }).then((resp) => {
        const students = (resp || []);
        if (students.length === 0 ) {
          setStudent(null);
          setErrorMsg(`Can NOT find any student with (${keywords}).`)
          return;
        }
        setStudent(students[0]);
      }).catch(error => {
        setErrorMsg(error.response.data.message || error.message)
      }).finally(() => {
        setIsSearching(false)
      })
  }

  const getSearchPanel = () => {
    if (isShowingEmailSearching === true) {
      return (
        <StudentAbsenceContentPanel>
          <StudentEmailSearchPanel
            onSearch={searchStudent}
            errorMsg={errorMsg}
            isLoading={isSearching}
          />
        </StudentAbsenceContentPanel>
      );
    }
    return (
      <StudentAbsenceContentPanel>
        <StudentIDSearchPanel
          onSearch={searchStudent}
          errorMsg={errorMsg}
          isLoading={isSearching}
        />
      </StudentAbsenceContentPanel>
    );
  }


  const getContent = () => {
    if (student) {
      return (
        <StudentAbsenceTypeSelectPanel
          student={student}
          style={[studentAbsenceScreenStyle.searchPanel]}
          onCancel={() => setStudent(null)}
          onSubmitted={() => setStudent(null)}
        />
      );
    }
    return (
      <View style={[studentAbsenceScreenStyle.searchPanel]}>
        {getSearchTypeSwitch()}
        {getSearchPanel()}
      </View>
    )
  }

  return (
    <View style={[
      studentAbsenceScreenStyle.screen
    ]}>
      <HStack
        justifyContent={'space-between'}
        style={[
          studentAbsenceScreenStyle.header
        ]}
      >
        <HStack justifyContent={'center'}>
          <Logo onNav={() => navigation.push(Urls.DEVICE_INFO)} />
          <Center>
            <Text style={[screenStyle.h3, screenStyle.redScreenText]}>Mentone Girls' Grammar</Text>
          </Center>
        </HStack>
        <View>
          <Text style={[screenStyle.h2, screenStyle.redScreenText]}>Sign In & Sign Out</Text>
        </View>
      </HStack>
      <Center>
        {getContent()}
      </Center>
    </View>
  )
}

export default StudentAbsenceScreen;
