import iVStudent from '../../../types/Synergetic/iVStudent';
import Text from '../../../components/framework/Text';
import Heading from '../../../components/framework/Heading';
import HStack from '../../../components/framework/HStack';
import View from '../../../components/framework/View';
import Button from '../../../components/framework/Button';
import {Icons} from '../../../components/framework/Icon';
import {screenStyle} from '../../../AppStyles';
import {
  iRecordTypeMap, iStudentAbsence,
  STUDENT_ABSENCE_REASON_CODE_OTHER,
} from '../../../types/StudentAbsence/iStudentAbsence';
import Center from '../../../components/framework/Center';
import FormControl, {FormControlLabel} from '../../../components/framework/FormControl';
import SynLuAbsenceReasonSelector from '../../../components/StudentAbsence/SynLuAbsenceReasonSelector';
import {useEffect, useRef, useState} from 'react';
import Input from '../../../components/framework/Input';
import FormControlErrorDiv from '../../../components/FomControlErrorDiv';
import StudentAbsenceService from '../../../services/StudentAbsences/StudentAbsenceService';
import moment from 'moment';
import {useToast} from 'native-base';
import Toaster from '../../../components/common/Toaster';
import MggsModuleService from '../../../services/MGGSModule/MggsModuleService';
import {MGGS_MODULE_ID_STUDENT_ABSENCES} from '../../../types/modules/iModuleUser';

type iStudentAbsenceSubmitPanel = {
  student: iVStudent;
  absenceType: string;
  style?: any;
  onCancel: () => void;
  onSubmitted: () => void;
}
const StudentAbsenceSubmitPanel = ({student, onCancel, absenceType, style = [], onSubmitted}: iStudentAbsenceSubmitPanel) => {
  const toaster = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [reasonCode, setReasonCode] = useState('');
  const [errorMap, setErrorMap] = useState<{[key: string]: string}>({});
  const [extraAbsenceTypeCodes, setExtraAbsenceTypeCodes] = useState<string[]>([]);
  const [, setIsLoading] = useState(false);

  useEffect(() => {
    let isCanceled = false;
    setIsLoading(true);
    Promise.all([
      MggsModuleService.getModule(MGGS_MODULE_ID_STUDENT_ABSENCES),
    ])
      .then(resp => {
        setExtraAbsenceTypeCodes(`${resp[0].settings?.extraAbsenceTypeCodes || ''}`.trim().split(','))
      })
      .catch(err => {
        if (isCanceled) return;
      })
      .finally(() => {
        if (isCanceled) return;
        setIsLoading(false);
      })

    return () => {
      isCanceled = true;
    }
  }, []);

  const comments = useRef('');

  const getAbsenceTypeName = () => {
    return StudentAbsenceService.getAbsenceTypeName(absenceType);
  }

  const getCommentsDiv = () => {
    if (reasonCode !== STUDENT_ABSENCE_REASON_CODE_OTHER) {
      return null;
    }
    return(
      <FormControl isRequired style={{marginTop: 14}}>
        <FormControlLabel>
          <Text style={[screenStyle.redScreenText, screenStyle.h4]}>Comments</Text>
        </FormControlLabel>
        <Input
          isDisabled={isSubmitting}
          defaultValue={comments.current}
          placeholder={'The reason in details...'}
          w={'100%'}
          style={{backgroundColor: 'white', paddingTop: 14, paddingBottom: 14}}
          onChangeText={(newTxt) => {
            comments.current = newTxt;
          }}
        />
        <FormControlErrorDiv errorMsg={errorMap.comments || ''} />
      </FormControl>
    )
  }

  const preSubmit = () => {
    const errors: {[key: string]: string} = {};
    if (`${reasonCode}`.trim() === '') {
      errors.reasonCode = `Reason is required.`
    }

    if (reasonCode === STUDENT_ABSENCE_REASON_CODE_OTHER && `${comments.current}`.trim() === '') {
      errors.comments = `Comments is required.`
    }

    setErrorMap(errors);
    return Object.keys(errors).length <= 0;
  }

  const submit = () => {
    if (preSubmit() !== true) {
      return;
    }
    const data = {
      type: absenceType,
      StudentID: student.StudentID,
      EventDate: moment().toISOString(),
      AbsenceCode: reasonCode,
      Comments: comments.current,
    }
    setIsSubmitting(true);
    StudentAbsenceService.submitByStudent(data).then(resp => {
      comments.current = '';
      setReasonCode('');
      setErrorMap({});
      // console.log('resp', resp);
      Toaster.showSuccess(`Submitted Successfully`, toaster);
      onSubmitted();
    }).catch(err => {
      Toaster.showApiErr(err, toaster);
    }).finally(() => {
      setIsSubmitting(false);
    })
  }

  const getContent = () => {
    return (
      <>
        <Center>
          <Heading style={[screenStyle.redScreenText]}>
            {getAbsenceTypeName()}
          </Heading>
        </Center>
        <FormControl isRequired  style={{marginTop: 14}}>
          <FormControlLabel>
            <Text style={[screenStyle.redScreenText]}>Reason for {getAbsenceTypeName()}</Text>
          </FormControlLabel>
          <SynLuAbsenceReasonSelector
            isDisabled={isSubmitting}
            dropdownIcon={
              <Center style={{backgroundColor: 'white', paddingRight: 12, paddingTop: 14, paddingBottom: 14}}>
                <Icons.ChevronDownIcon />
              </Center>
            }
            addOtherRegardless={true}
            value={reasonCode}
            style={{backgroundColor: 'white', paddingTop: 14, paddingBottom: 14}}
            color={'muted.900'}
            placeHolder={'Select a reason...'}
            placeholderTextColor={'muted.900'}
            absenceTypeCodes={absenceType in iRecordTypeMap ? [iRecordTypeMap[absenceType], ...extraAbsenceTypeCodes] : []}
            onSelect={(reason) => setReasonCode(reason.Code || '')}
          />
          <FormControlErrorDiv errorMsg={errorMap.reasonCode || ''} />
        </FormControl>
        {getCommentsDiv()}
        <HStack justifyContent={'space-between'} style={{marginTop: 30}}>
          <Button
            isLoading={isSubmitting}
            colorScheme={'primary'}
            variant={'unstyled'}
            onPress={() => onCancel()}
            leftIcon={<Icons.CloseIcon style={[screenStyle.redScreenText]} />}
          >
            <Text style={[screenStyle.redScreenText]}>Cancel</Text>
          </Button>
          <Button
            isLoading={isSubmitting}
            style={{paddingTop: 14, paddingBottom: 14, width: '30%'}}
            colorScheme={'primary'}
            onPress={() => submit()}
            leftIcon={<Icons.Submit />}
          >
            Submit
          </Button>
        </HStack>
      </>
    )
  }

  return (
    <View style={style}>
      {getContent()}
    </View>
  )
}

export default StudentAbsenceSubmitPanel;
