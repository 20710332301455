import iVStudent from '../../../types/Synergetic/iVStudent';
import Text from '../../../components/framework/Text';
import StudentAbsenceContentPanel from './StudentAbsenceContentPanel';
import {screenStyle, studentAbsenceScreenStyle} from '../../../AppStyles';
import Heading from '../../../components/framework/Heading';
import HStack from '../../../components/framework/HStack';
import View from '../../../components/framework/View';
import CurrentTime from '../../../components/CurrentTime';
import Center from '../../../components/framework/Center';
import {Icons} from '../../../components/framework/Icon';
import Button from '../../../components/framework/Button';
import {useEffect, useState} from 'react';
import {
  iStudentAbsence,
  STUDENT_ABSENCE_RECORD_TYPE_EARLY_SIGN_OUT,
  STUDENT_ABSENCE_RECORD_TYPE_LATE_SIGN_IN
} from '../../../types/StudentAbsence/iStudentAbsence';
import StudentAbsenceSubmitPanel from './StudentAbsenceSubmitPanel';
import moment from 'moment/moment';
import StudentAbsenceService from '../../../services/StudentAbsences/StudentAbsenceService';
import {OP_BETWEEN, OP_GTE, OP_LIKE, OP_LTE} from '../../../helper/ServiceHelper';
import Toaster from '../../../components/common/Toaster';
import {useToast} from 'native-base';
import Spinner from '../../../components/framework/Spinner';
import StudentAbsenceSubmitByExpectedPanel from './StudentAbsenceSubmitByExpectedPanel';
import StudentScheduledAbsenceService from '../../../services/StudentAbsences/StudentScheduledAbsenceService';

type iStudentAbsenceTypeSelectPanel = {
  student: iVStudent;
  style?: any;
  onCancel: () => void;
  onSubmitted: () => void;
}
const StudentAbsenceTypeSelectPanel = ({student, onCancel, onSubmitted, style = []}: iStudentAbsenceTypeSelectPanel) => {
  const toaster = useToast();
  const [absenceType, setAbsenceType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [expectedEvents, setExpectedEvents] = useState<iStudentAbsence[]>([]);

  useEffect(() => {
    if (`${absenceType || ''}`.trim() === '') {
      return;
    }
    let isCanceled = false;
    const now = moment();
    const dayOfWeek = now.format('dddd').trim().toLowerCase();
    setIsLoading(true);
    Promise.all([
      StudentAbsenceService.getAll({
        where: JSON.stringify({
          type: absenceType,
          StudentID: student.StudentID,
          isExpectedEvent: true,
          active: true,
          EventDate: {
            [OP_BETWEEN]: [now.clone().subtract(4, 'hour').format('YYYY-MM-DD 00:00:00'), now.clone().add(4, 'hour').format('YYYY-MM-DD 23:59:59')],
          }
        }),
        include: 'AbsenceReason'
      }),
      ...(['saturday', 'sunday'].indexOf(dayOfWeek) < 0 ? [
        StudentScheduledAbsenceService.getAll({
          where: JSON.stringify({
            active: true,
            eventType: absenceType,
            startDate: {[OP_LTE]: now.clone().format('YYYY-MM-DD')},
            endDate: {[OP_GTE]: now.clone().format('YYYY-MM-DD')},
            [dayOfWeek]: true,
          }),
          include: 'Event.AbsenceReason'
        })
      ] : [])
    ]).then(resp => {
      if (isCanceled) { return }
      setExpectedEvents(Object.values(
        [
          ...(resp[0].data || []),
          ...(resp[1].data || []).map(record => record.Event || null).filter(event => `${event.id || ''}`.trim() !== '')
        ].filter(event => event.StudentID === student.StudentID).reduce((map, record) => ({...map, [record.id]: record}), {})
      ))
    }).catch(err =>{
      if (isCanceled) { return }
      Toaster.showApiErr(err, toaster);
    }).finally(() => {
      if (isCanceled) { return }
      setIsLoading(false);
    })

    return () => {
      isCanceled = true;
    }
  }, [student, absenceType])

  const getContent = () => {
    if (isLoading === true) {
      return (
        <View style={{marginTop: 12, marginBottom: 12}}>
          <Center>
            <Spinner />
          </Center>
        </View>
      )
    }

    if (`${absenceType}`.trim() !== '') {
      if (expectedEvents.length > 0) {
        return <StudentAbsenceSubmitByExpectedPanel
          style={{marginTop: 12, marginBottom: 12}}
          student={student}
          absenceType={absenceType}
          expectedEvents={expectedEvents}
          onCancel={() => setAbsenceType('')}
          onBackToNormal={() => setExpectedEvents([])}
          onSubmitted={onSubmitted}
        />
      }
      return <StudentAbsenceSubmitPanel
        style={{marginTop: 12, marginBottom: 12}}
        student={student}
        absenceType={absenceType}
        onCancel={() => setAbsenceType('')}
        onSubmitted={onSubmitted}
      />;
    }

    return (
      <View style={{marginTop: 12, marginBottom: 12}}>
        <Heading style={[screenStyle.redScreenText, {marginBottom: 12}]}>You want to?</Heading>
        <HStack justifyContent={'center'} space={5}>
          <Button
            colorScheme={'primary'}
            style={[studentAbsenceScreenStyle.styleSelectBtn]}
            onPress={() => setAbsenceType(STUDENT_ABSENCE_RECORD_TYPE_LATE_SIGN_IN)}
          >
            <Center>
              <Icons.SignIn size={20} color={'white'} />
              <Text style={[screenStyle.redScreenText, screenStyle.h4]}>Sign In</Text>
            </Center>
          </Button>
          <Button
            colorScheme={'danger'}
            style={[studentAbsenceScreenStyle.styleSelectBtn]}
            onPress={() => setAbsenceType(STUDENT_ABSENCE_RECORD_TYPE_EARLY_SIGN_OUT)}
          >
            <Center>
              <Icons.SignIn size={20} color={'white'}  />
              <Text style={[screenStyle.redScreenText, screenStyle.h4]}>Sign out</Text>
            </Center>
          </Button>
        </HStack>
      </View>
    )
  }

  return (
    <StudentAbsenceContentPanel style={style}>
      <View style={{borderBottomColor: 'white', borderBottomWidth: 1, paddingBottom: 12}}>
        <HStack justifyContent={'space-between'}>
          <View>
            <Heading style={[screenStyle.redScreenText]}>{student.StudentSurname}, {student.StudentGiven1}</Heading>
            <CurrentTime title={'Current Time: '} textStyle={[screenStyle.redScreenText]} />
          </View>
          <Heading style={[screenStyle.redScreenText]} onPress={() => onCancel()}>X</Heading>
        </HStack>
      </View>

      {getContent()}

      <Center style={{paddingTop: 24}}>
        <Text onPress={() => onCancel()}  style={[screenStyle.redScreenText]}>
          This is not you? please click here
        </Text>
      </Center>
    </StudentAbsenceContentPanel>
  )
}

export default StudentAbsenceTypeSelectPanel;
