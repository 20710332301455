import {Icon as Original,
  IIconProps,
  MoonIcon,
  SunIcon,
  CheckIcon,
  CircleIcon,
  ArrowBackIcon,
  AddIcon,
  ArrowForwardIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  CloseIcon,
  SmallCloseIcon,
  HamburgerIcon,
  InfoIcon,
  InfoOutlineIcon,
  MinusIcon,
  QuestionIcon,
  QuestionOutlineIcon,
  SearchIcon,
  WarningIcon,
  WarningTwoIcon,
  ThreeDotsIcon,
  WarningOutlineIcon,
  ShareIcon,
  PlayIcon,
  FavouriteIcon,
  DeleteIcon} from "native-base";
import { FontAwesome } from '@expo/vector-icons';



type iIcon = IIconProps
const Icon = (props: IIconProps) => {
  return <Original {...props} />;
}

export const Icons = {
  MoonIcon,
  SunIcon,
  CheckIcon,
  CircleIcon,
  ArrowBackIcon,
  AddIcon,
  ArrowForwardIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  CloseIcon,
  SmallCloseIcon,
  HamburgerIcon,
  InfoIcon,
  InfoOutlineIcon,
  MinusIcon,
  QuestionIcon,
  QuestionOutlineIcon,
  SearchIcon,
  WarningIcon,
  WarningTwoIcon,
  ThreeDotsIcon,
  WarningOutlineIcon,
  ShareIcon,
  PlayIcon,
  FavouriteIcon,
  DeleteIcon,
  HomeIcon: (props: iIcon) => <Icon as={<FontAwesome name={'home'} />} {...props} />,
  SignIn: (props: iIcon) => <Icon as={<FontAwesome name={'sign-in'} />} {...props} />,
  SignOut: (props: iIcon) => <Icon as={<FontAwesome name={'sign-out'} />} {...props} />,
  Submit: (props: iIcon) => <Icon as={<FontAwesome name={'paper-plane'} />} {...props} />,
}

export default Icon;
