import {useEffect, useState} from 'react';
import iSynLuAbsenceReason from '../../types/StudentAbsence/iSynLuAbsenceReason';
import SynLuAbsenceReasonService from '../../services/Synergetic/SynLuAbsenceReasonService';
import {OP_OR} from '../../helper/ServiceHelper';
import {STUDENT_ABSENCE_REASON_CODE_OTHER} from '../../types/StudentAbsence/iStudentAbsence';
import Select, {SelectItem} from '../framework/Select';
import Spinner from '../framework/Spinner';
import {Icons} from '../framework/Icon';

type iSynLuAbsenceReasonSelector = {
  value: string;
  absenceTypeCodes?: string[];
  onSelect?: (reason: iSynLuAbsenceReason | null) => void;
  style?: any;
  isDisabled?: boolean;
  addOtherRegardless?: boolean;
  color?: string;
  placeHolder?: string;
  placeholderTextColor?: string;
  dropdownIcon?: any;
};

const getLabel = (SynLuAbsenceReason: iSynLuAbsenceReason) => {
  return `${SynLuAbsenceReason.Code} - ${SynLuAbsenceReason.Description}${`${SynLuAbsenceReason.AbsenceTypeCode || ''}`.trim() === '' ? '' : ` [${SynLuAbsenceReason.AbsenceTypeCode || ''}]`}`;
}

const SynLuAbsenceReasonSelector = ({onSelect, style, color, placeholderTextColor, dropdownIcon, value, placeHolder, absenceTypeCodes = [], isDisabled = false, addOtherRegardless = false}: iSynLuAbsenceReasonSelector) => {
  const [optionsMap, setOptionsMap] = useState<{[key: string]: iSynLuAbsenceReason}>({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let isCancelled = false;
    setIsLoading(true);
    const whereOrObjArr = [];
    if (absenceTypeCodes?.length > 0) {
      whereOrObjArr.push({AbsenceTypeCode : absenceTypeCodes});
    }
    if (addOtherRegardless === true) {
      whereOrObjArr.push({Code : STUDENT_ABSENCE_REASON_CODE_OTHER});
    }
    // @ts-ignore
    SynLuAbsenceReasonService.getAll({
        where: JSON.stringify({
          ActiveFlag: true,
          ...(whereOrObjArr.length === 0 ? {} : (whereOrObjArr.length === 1 ? whereOrObjArr[0] : {[OP_OR]: whereOrObjArr}))
        }),
        sort: 'Description:ASC',
      })
      .then(resp => {
        if (isCancelled === true) { return }
        setOptionsMap(resp.reduce((map, SynLuAbsenceReason) => {
          return {
            ...map,
            [SynLuAbsenceReason.Code]:SynLuAbsenceReason,
          };
        }, {}))
      })
      .catch(err => {
        if (isCancelled === true) { return }
      })
      .finally(() => {
        if (isCancelled === true) { return }
        setIsLoading(false);
      })
    return () => {
      isCancelled = true;
    }
  }, [absenceTypeCodes, addOtherRegardless]);

  if (isLoading === true) {
    return <Spinner size={'sm'} />;
  }

  return (
    <Select
      isDisabled={isDisabled}
      dropdownIcon={dropdownIcon}
      style={style}
      variant={'unstyled'}
      placeholder={placeHolder}
      placeholderTextColor={placeholderTextColor}
      color={color}
      selectedValue={value}
      onValueChange={(value) => onSelect(value in optionsMap ? optionsMap[value] : null)}
      _selectedItem={{
        bg: "teal.600",
        endIcon: <Icons.CheckIcon size="5" />
      }}
    >
      {
        Object.keys(optionsMap).map(key => {
          return (
            <SelectItem key={key} label={getLabel(optionsMap[key])} value={key} />
          )
        })
      }
    </Select>
  )
};

export default SynLuAbsenceReasonSelector;
